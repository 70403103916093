import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import GoHomeOrGoHunt from "./GoHomeOrGoHunt";

const DisabledProductPlaceholder = ({
  text = "This product doesn't appear to be supported, anymore...",
  iconName = "chess knight"
}) => (
  <Segment placeholder>
    <Header icon>
      <Icon name={iconName} />
      {text}
    </Header>
    <GoHomeOrGoHunt />
  </Segment>
);

export default DisabledProductPlaceholder;
