import React from 'react';
import {Button, ButtonGroup} from "semantic-ui-react";
import {Link} from "gatsby";


const GoHomeOrGoHunt = () => (
    <ButtonGroup vertical>
        <Button primary as={Link} to="/">
            Go Home
        </Button>
        <Button secondary as="a" href="https://duckhuntjs.com/">
            Distraction?
        </Button>
    </ButtonGroup>
);

export  default GoHomeOrGoHunt