import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Label } from 'semantic-ui-react';

import { ProductsContext } from './ProductsProvider';
// import { CartContext } from './CartProvider';

import DisabledProductPlaceholder from './DisabledProductPlaceholder';
import SemanticGatsbyImageWrapper from './SemanticGatsbyImageWrapper';
import CallTodayModal from './CallTodayModal';

const ProductPage = ({ productId }) => {
  const { products } = useContext(ProductsContext);
  // const { add, toggle } = useContext(CartContext);

  const product = products[productId];
  const { active, name, caption, description, localFiles } = product;

  return active ? (
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              {name}
            </Header>
            <Header sub>{caption}</Header>

            <Segment raised>
              <Label color="green" ribbon>
                What you get
              </Label>
              <p>{description}</p>
            </Segment>

            {/*
            <Button
              size="large"
              primary
              onClick={() => {
                add(product.skus[0].id);
                toggle();
              }}
            >
              Schedule your Hunt today
            </Button>
           */}
            <CallTodayModal />
          </Grid.Column>

          <Grid.Column width={10}>
            <SemanticGatsbyImageWrapper
              fluidUri={localFiles[0].childImageSharp.fluid}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  ) : (
    <DisabledProductPlaceholder />
  );
};

ProductPage.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default ProductPage;
