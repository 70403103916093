import React from "react";
import GatsbyImage from "gatsby-image";

const SemanticGatsbyImageWrapper = ({
  className = "ui large bordered rounded image",
  fluidUri,
  fixedUri
}) => (
  <div className={className}>
    {fluidUri ? (
      <GatsbyImage fluid={fluidUri} />
    ) : (
      <GatsbyImage fixed={fixedUri} />
    )}
  </div>
);

export default SemanticGatsbyImageWrapper;
